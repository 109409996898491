import React from 'react';

import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLabels } from 'wsm-common-data';

import WhyBuyItems from '../components/WhyBuyItems';

import '../global-sass/main.scss';

const Widget = () => {
	const labels = useLabels();
	const items = useSelector((state) => state.items);
	const isComposer = useSelector((state) => state.widgetInfo);

	if (items.length < 3) {
		if (isComposer) {
			return (
				<Alert bsStyle="info">
					<i aria-hidden="true" className="ddc-icon ddc-icon-alert" />
					<div className="alert-content">
						{labels.get(
							'WHY_BUY_CLICK_TO_ENABLE_SERVICES_VERBIAGE'
						)}
					</div>
				</Alert>
			);
		}
		return null;
	}

	return <WhyBuyItems {...{ items }} />;
};

export default Widget;
